export class UserForm {
  constructor(
    private name: string,
    private type: string,
    private contact_number1: string,
    private contact_number2: string,
    private email: string,
    private country: string,
    private state: string,
    private city: string,
    private address: string,
    private pincode: string,
    private document: string
  ) {}
}

export class User extends UserForm {
  constructor(
    private id: string,
    name: string,
    type: string,
    contact_number1: string,
    contact_number2: string,
    email: string,
    country: string,
    state: string,
    city: string,
    address: string,
    pincode: string,
    document: string
  ) {
    super(
      name,
      type,
      contact_number1,
      contact_number2,
      email,
      country,
      state,
      city,
      address,
      pincode,
      document
    );
  }
}
