import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EMPTY } from 'rxjs';
import { User } from '../model/user';
import { baseSelect } from './joborder';

export interface userstatemodel {
  list: User[];
  item: User | null;
  type: baseSelect[];
}

export class FetchUserList {
  static readonly type = '[FetchUserList] UserState';
}

export class FetchUserById {
  static readonly type = '[FetchUserById] UserState';
  constructor(public id: string) {}
}

@State<userstatemodel>({
  name: 'user',
  defaults: {
    list: [
      new User(
        '1',
        'Mugunthan',
        '1',
        '8754713794',
        '8220476727',
        'kskarthick93@gmail.com',
        '101',
        '567',
        '14137',
        '18D Nethaji Colony',
        '612001',
        '1'
      ),
      new User(
        '1',
        'Mugunthan',
        '1',
        '8754713794',
        '8220476727',
        'kskarthick93@gmail.com',
        '101',
        '567',
        '14137',
        '18D Nethaji Colony',
        '612001',
        '1'
      ),
      new User(
        '1',
        'Mugunthan',
        '1',
        '8754713794',
        '8220476727',
        'kskarthick93@gmail.com',
        '101',
        '567',
        '14137',
        '18D Nethaji Colony',
        '612001',
        '1'
      ),
      new User(
        '1',
        'Mugunthan',
        '1',
        '8754713794',
        '8220476727',
        'kskarthick93@gmail.com',
        '101',
        '567',
        '14137',
        '18D Nethaji Colony',
        '612001',
        '1'
      ),
      new User(
        '1',
        'Mugunthan',
        '1',
        '8754713794',
        '8220476727',
        'kskarthick93@gmail.com',
        '101',
        '567',
        '14137',
        '18D Nethaji Colony',
        '612001',
        '1'
      ),
    ],
    item: null,
    type: [
      {
        label: 'Internal',
        value: '1',
      },
      {
        label: 'External',
        value: '2',
      },
    ],
  },
})
@Injectable()
export class UserState {
  constructor() {}

  @Selector()
  static getList(state: userstatemodel): any[] {
    return state.list;
  }

  @Selector()
  static getItem(state: userstatemodel): any {
    return state.item;
  }

  @Selector()
  static getType(state: userstatemodel): any {
    return state.type;
  }

  @Action(FetchUserList)
  fetchClientList(
    states: StateContext<userstatemodel>,
    action: FetchUserList
  ) {}

  @Action(FetchUserById)
  fetchClientById(states: StateContext<userstatemodel>, action: FetchUserById) {
    states.patchState({
      item: new User(
        '1',
        'Mugunthan',
        '1',
        '8754713794',
        '8220476727',
        'kskarthick93@gmail.com',
        '101',
        '567',
        '14137',
        '18D Nethaji Colony',
        '612001',
        '1'
      ),
    });

    return EMPTY;
  }
}
